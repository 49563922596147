import React, {useEffect} from "react";
import { Container } from "react-bootstrap";
import Footer from "../Footer";
import NavBar from "../Navbar";

function Terms() {

    useEffect(() => {
        document.title = "Terms of Service - Insta VPN";
    }, []);


    return (
        <Container

            fluid
            className="home-content p-4 ms-lg-12 me-lg-12 text-xs font-weight-light mx-auto"
            style={{ maxWidth: '1200px' }}
        >
            <div className={"mt-5 pt-5  "}>

                <NavBar/>
            </div>
            <h4>Terms of Service</h4>

            <p>Thank you for choosing InstaVPN! Before you start using our services, please read these General Terms of Service (“General Terms”) carefully.</p>

            <h4>1. Your Agreement with Insta VPN</h4>
            <p>Binding Legal Agreement:</p>
            <p>Please note that by downloading our Software, accessing/using our Services and Websites, or otherwise agreeing electronically, you enter into a binding legal agreement with Insta VPN. You represent that you have the authority to bind yourself or any organization you represent to these Terms.</p>

            <h4>2. Acceptance of Terms</h4>
            <p>Our Services are offered to you conditional on your acceptance without modification of all the Terms. By accessing and using our Services and/or Websites, you agree to these Terms. If you do not agree, please do not use our Services and Websites.</p>
            <p>THE TERMS MAY CHANGE, SO IT IS IMPORTANT TO REVIEW THE TERMS PERIODICALLY.</p>

            <h4>3. Our Services</h4>

            <h5>Eligibility</h5>
            <p>You affirm that you are at least eighteen (18) years old or have the authority to act on behalf of a valid legal entity, fully able to enter into and comply with these Terms.</p>

            <h5>Updates to the Terms</h5>
            <p>We may update these Terms to reflect changes in our Services, legal requirements, or other aspects. We will provide advance notice of material changes. Continued use of the Services after changes constitutes acceptance of the new Terms.</p>

            <h5>Use at Own Risk</h5>
            <p>Your use of our service is at your own risk. Services may be modified, updated, interrupted, or suspended at any time without prior notice or liability.</p>

            <h5>User Responsibility</h5>
            <p>You are responsible for maintaining information generated during your use and for all activities under your service use.</p>

            <h4>4. License Terms</h4>

            <h5>License Grant</h5>
            <p>Subject to these Terms, we grant you the right to use our Software on devices you own or control.</p>

            <h5>Rights Retention</h5>
            <p>We retain all rights to the Software and Services. The Software is licensed, not sold, to you.</p>

            <h4>5. Prohibited and Restricted Use</h4>

            <h5>Compliance with Laws</h5>
            <p>Your access to and use of our Services must comply with all applicable laws and regulations. We reserve the right to suspend or terminate access for violations.</p>

            <h5>Prohibited Activities</h5>
            <p>You must not engage in illegal, unethical, or harmful activities, including but not limited to fraud, spam, hacking, and infringement of intellectual property rights.</p>

            <h4>6. Disclaimer of Warranties</h4>

            <h5>‘As Is’ Provision</h5>
            <p>Our services are provided “as is” without any warranties of completeness, accuracy, or functionality. InstaVPN disclaims all warranties, to the extent permitted by applicable law.</p>

            <h4>7. Limitation of Liabilities</h4>

            <h5>Risk Acknowledgment</h5>
            <p>You acknowledge inherent risks in using internet-based services. InstaVPN is not liable for user behavior or any damages arising from the use of our Services.</p>

            <h5>Liability Exclusions</h5>
            <p>InstaVPN is not liable for direct, indirect, or consequential damages related to these Terms or the use of our Services.</p>

            <h4>8. GDPR Compliance Addendum</h4>

            <h5>Data Protection Commitment</h5>
            <p>We are committed to protecting your data in accordance with GDPR. This includes lawful processing of personal data, user rights to access and control their data, and measures for cross-border data transfer.</p>

            <h5>Data Breach Procedures</h5>
            <p>In case of a data breach, we will notify affected parties as required by GDPR.</p>

            <h5>Contact for Data Protection</h5>
            <p>For any data protection inquiries or to exercise your GDPR rights, please contact our Data Protection Officer.</p>

            <h4>9. Free Service and Advertisements</h4>

            <p>InstaVPN offers more than 30 server locations worldwide, with free access to servers. The only way we generate revenue is through advertising displayed within the app. We do not charge users for using the VPN services.</p>

            <h4>10. Data Collection and Privacy</h4>

            <p>We do not collect any personal information from our users, except for the advertising ID, which is used for displaying relevant ads. We are committed to ensuring the privacy and security of your data, and no personally identifiable information is shared or sold to third parties.</p>

            <p>For more information, please refer to our <a href="/privacy">Privacy Policy</a> to understand how we handle data in compliance with legal requirements.</p>

            <h4>11. Contact Us</h4>
            <p>For questions about these Terms, please contact us at <a href="mailto:support@xmmobilelab.com">support@xmmobilelab.com</a>.</p>
            <p>For abuse allegations, refer to the specific service terms.</p>
            <p>Final Interpretation Rights:</p>
            <p>The final right of interpretation of these Terms belongs to Insta VPN.</p>
        </Container>
    );
}

export default Terms;
